import React, { ReactNode, useState } from 'react';
import queryString from 'query-string';
import classnames from 'classnames';

import utilStyles from '../styles/util.module.scss';
import { ReqStatus } from '../types';
import Alert from './Alert';
import Button from './Button';
import Input from './form/Input';
import TextArea from './form/TextArea';
import Grid from './layout/Grid';
import styles from './RequestQuoteForm.module.scss';
import { useLocation } from 'react-router-dom';
import appConstants from '../appConstants';
import { requestQuote } from '../apiClients/miscApiClient';
import Select from './form/Select';
import { getHigherEdSelectOps, getK12InterestSelectOps, QuoteInterest } from '../services/requestQuoteService';
import GetStarted from '../media/reqQuoteSuccess.svg';
import GetStartedEdu from '../media/reqQuoteSuccessEdu.svg';
import Link from './Link';
import CallToAction from './CallToAction';
import { isValidEmail } from '../services/validationService';
import { getPageName } from '../routes';
import { getSegmentAnonymousId } from '../services/analyticsService';

type Props = {
  id?: string;
  title?: string;
  subtitle?: ReactNode;
  entity?: 'Company/Organization' | 'School/District' | 'Institution';
  submitLabel?: string;
  analyticsEventLabel?: string;
  fullWidthFields?: boolean;
  leftAlignFields?: boolean;
  leftAlignTitle?: boolean;
  media?: React.ReactNode;
  className?: string;
  classes?: {
    title?: string;
  };

  requestSubmittedText?: string;
  nextStepText?: string;
  nextStepLink?: string;
  nextStepLabel?: string;
  isEdu?: boolean;
};

const defaultProps: Partial<Props> = {
  title: 'Want to learn more? Request a free quote!',
  subtitle: 'We’ll work with you to build a custom solution that meets yours needs & budget.',
  entity: 'Company/Organization',
  submitLabel: 'Send message',
  analyticsEventLabel: 'Marketing Site',

  requestSubmittedText: 'Thanks! Our flock will contact you shortly.',
  nextStepText: 'Next step? Create your first Goosechase Experience for free!',
  nextStepLabel: 'Get started',
  isEdu: false,
};

const RequestQuoteForm: React.FunctionComponent<Props> = (props) => {
  const [generalErr, setGeneralErr] = useState('');
  const [name, setName] = useState('');
  const [nameErr, setNameErr] = useState('');
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [company, setCompany] = useState('');
  const [companyErr, setCompanyErr] = useState('');
  const [participantCount, setParticipantCount] = useState('');
  const [participantCountErr, setParticipantCountErr] = useState('');
  const [message, setMessage] = useState('');
  const [messageErr, setMessageErr] = useState('');
  const [interest, setInterest] = useState<QuoteInterest>('');
  const [interestErr, setInterestErr] = useState('');
  const [submitStatus, setSubmitStatus] = useState<ReqStatus>();

  const location = useLocation();

  const renderMessagePlaceholder = () => {
    let base = "What are you planning? What's your goal? Who will be participating?";
    if (!props.entity) {
      return base;
    }

    switch (interest) {
      case 'school_wide_license':
        return "Is there anything else you'd like to mention about your " + props.entity.toLowerCase() + '?';
      case 'field_trip_license':
      case 'staff_team_building':
        return "Is there anything else you'd like to mention about your game or " + props.entity.toLowerCase() + '?';
      case 'other':
        return 'How can we help?';
      default:
        return base;
    }
  };

  const renderParticipantCountLabel = () => {
    let base = 'How many participants? (approx!)';
    if (!props.entity) {
      return base;
    }

    switch (interest) {
      case 'school_wide_license':
        return `${props.entity} Population (# of Students/Staff)`;
      case 'single_event_license':
        return 'Estimated Event Attendance';
      case 'staff_team_building':
      case 'field_trip_license':
        return '# of Participating Students/Staff';
      default:
        return base;
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setNameErr('');
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailErr('');
  };

  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
    setCompanyErr('');
  };

  const handleParticipantCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParticipantCount(e.target.value);
    setParticipantCountErr('');
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    setMessageErr('');
  };

  const handleInterestChange = (e: React.ChangeEvent<HTMLSelectElement & { value: QuoteInterest }>) => {
    setInterest(e.target.value);
    setInterestErr('');
  };

  const checkFormErrors = () => {
    let hasError = false;

    if (!name) {
      setNameErr('This field is required.');
      hasError = true;
    }

    if (!isValidEmail(email)) {
      setEmailErr('A valid email is required.');
      hasError = true;
    }

    if (interestSelectOps) {
      if (!interest) {
        setInterestErr('This field is required.');
        hasError = true;
      }
    }

    if (showCompanyAndParticipantFields) {
      if (!company) {
        setCompanyErr('This field is required.');
        hasError = true;
      }

      if (!participantCount) {
        setParticipantCountErr('This field is required.');
        hasError = true;
      }
    }

    if (!message) {
      setMessageErr('This field is required.');
      hasError = true;
    }

    return hasError;
  };

  const handleSubmitRequest = async () => {
    const hasError = checkFormErrors();
    if (hasError) {
      return;
    }

    const [firstName, ...lastName] = name.split(' ');

    const queryStrParams = queryString.parse(location.search);

    setSubmitStatus('pending');
    setGeneralErr('');

    const res = await requestQuote({
      firstName: firstName,
      lastName: lastName.join(' '),
      email: email,
      company: company || 'Unknown', // Assign a company name of "unknown" if none specified to allow submission to Salesforce
      interestSelect: interest,
      participantCount: participantCount,
      message: message,
      sentFromPage: `${appConstants.BASE_URL}${location.pathname}`,
      utm_source: queryStrParams.utm_source as string,
      utm_medium: queryStrParams.utm_medium as string,
      utm_campaign: queryStrParams.utm_campaign as string,
      segmentDistinctId: getSegmentAnonymousId(),
      eventLabel: props.analyticsEventLabel,
      page: getPageName(location.pathname),
      isEdu: props.isEdu,
    });
    if (!res.success) {
      setGeneralErr("Something went wrong and your quote wasn't submitted. Please try again!");
      setSubmitStatus('err');
      return;
    }

    setSubmitStatus('success');
  };

  function renderFormFields() {
    const mdFieldWidth = props.fullWidthFields ? 12 : 6;
    const fields = (
      <Grid container spacing={3} className={styles.form}>
        {generalErr.length > 0 && (
          <Grid item xs={12}>
            <Alert type="error" title="Error" className={styles.alert}>
              {generalErr}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={mdFieldWidth}>
          <Input
            id="requestQuoteName"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={handleNameChange}
            disabled={inputsDisabled}
            error={nameErr}
          />
        </Grid>
        <Grid item xs={12} md={mdFieldWidth}>
          <Input
            id="requestQuoteEmail"
            label="Email"
            type="text"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            disabled={inputsDisabled}
            error={emailErr}
          />
        </Grid>
        {interestSelectOps && (
          <Grid item xs={12}>
            <Select
              id="requestQuoteInterest"
              label="What best describes your interest?"
              className={!interest ? styles.noInterestSelected : undefined}
              fullWidth
              value={interest}
              onChange={handleInterestChange}
              disabled={inputsDisabled}
              error={interestErr}
            >
              <option value="" disabled></option>
              {interestSelectOps.map((op) => (
                <option key={op.value} value={op.value}>
                  {op.label}
                </option>
              ))}
            </Select>
          </Grid>
        )}
        {showCompanyAndParticipantFields && (
          <React.Fragment>
            <Grid item xs={12} md={mdFieldWidth}>
              <Input
                id="requestQuoteCompanyName"
                label={`${props.entity} Name`}
                type="text"
                fullWidth
                value={company}
                onChange={handleCompanyChange}
                disabled={inputsDisabled}
                error={companyErr}
              />
            </Grid>
            <Grid item xs={12} md={mdFieldWidth}>
              <Input
                id="requestQuoteParticipantCount"
                label={renderParticipantCountLabel()}
                type="text"
                fullWidth
                value={participantCount}
                onChange={handleParticipantCountChange}
                disabled={inputsDisabled}
                error={participantCountErr}
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12}>
          <TextArea
            id="requestQuoteMessage"
            label="Give us a few details"
            rows={4}
            fullWidth
            placeholder={renderMessagePlaceholder()}
            value={message}
            onChange={handleMessageChange}
            disabled={inputsDisabled}
            error={messageErr}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            centered={!props.leftAlignFields}
            onClick={handleSubmitRequest}
            loading={inputsDisabled}
            disabled={inputsDisabled}
          >
            {props.submitLabel}
          </Button>
        </Grid>
      </Grid>
    );

    if (props.leftAlignFields) {
      return (
        <Grid container alignItems="center" justify="space-between">
          <Grid item md="auto" xs={12}>
            <div className={styles.leftAlignedFieldsContainer}>{fields}</div>
          </Grid>
          {props.media && (
            <Grid item md="flex" className={utilStyles.hiddenSmDown}>
              {props.media}
            </Grid>
          )}
        </Grid>
      );
    }

    return fields;
  }

  function renderSuccessState() {
    const nextStepBtn = props.nextStepLink ? (
      <Link variant="btn" href={props.nextStepLink}>
        {props.nextStepLabel}
      </Link>
    ) : (
      <CallToAction text={props.nextStepLabel} />
    );
    const normalPostSubmitSuccessContents = (
      <>
        <p>{props.requestSubmittedText}</p>
        <h2 className={styles.postSubmitTitle}>{props.nextStepText}</h2>
        <div className={styles.postSubmitImgWrapper}>
          {props.isEdu ? (
            <GetStartedEdu className={styles.postSubmitImg} />
          ) : (
            <GetStarted className={styles.postSubmitImg} />
          )}
        </div>
        {nextStepBtn}
      </>
    );

    if (props.leftAlignFields) {
      return (
        <>
          <Grid
            container
            alignItems="center"
            className={classnames(styles.postSubmitContainerLeft, utilStyles.hiddenSmDown)}
          >
            <Grid item sm={6}>
              <p>{props.requestSubmittedText}</p>
              <h2 className={styles.postSubmitTitleLeft}>{props.nextStepText}</h2>
              {nextStepBtn}
            </Grid>
            <Grid item sm={6}>
              {props.isEdu ? (
                <GetStartedEdu className={styles.postSubmitImgLeft} />
              ) : (
                <GetStarted className={styles.postSubmitImgLeft} />
              )}
            </Grid>
          </Grid>

          <div className={classnames(styles.postSubmitContainer, utilStyles.hiddenMdUp)}>
            {normalPostSubmitSuccessContents}
          </div>
        </>
      );
    }

    return <div className={styles.postSubmitContainer}>{normalPostSubmitSuccessContents}</div>;
  }

  const inputsDisabled = submitStatus === 'pending' || submitStatus === 'success';
  const showCompanyAndParticipantFields =
    props.entity === 'Company/Organization' || (!!interest && interest !== 'general_question' && interest !== 'other');

  let interestSelectOps: Array<{ value: QuoteInterest; label: string }> | undefined;
  if (props.entity === 'School/District') {
    interestSelectOps = getK12InterestSelectOps();
  } else if (props.entity === 'Institution') {
    interestSelectOps = getHigherEdSelectOps();
  }

  return (
    <div id={props.id} className={classnames(props.className, styles.root)}>
      <div className={submitStatus === 'success' ? styles.invisible : undefined}>
        <div
          className={classnames({
            [styles.titleContainer]: !props.leftAlignTitle,
            [styles.titleContainerLeft]: props.leftAlignTitle,
          })}
        >
          <h2 className={props.classes?.title || styles.title}>{props.title}</h2>
          <p className={styles.subtitle}>{props.subtitle}</p>
        </div>

        {renderFormFields()}
      </div>

      {submitStatus === 'success' && renderSuccessState()}
    </div>
  );
};

RequestQuoteForm.defaultProps = defaultProps;

export default RequestQuoteForm;
