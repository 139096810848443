import React from 'react';
import FormField, { FieldProps } from './FormField';

export type Props = React.ComponentProps<'textarea'> & Omit<FieldProps<'textarea'>, 'element' | 'elementProps'>;

// Simple wrapper around FormField for easier use
const TextArea = ({ fullWidth, error, label, variant, ...otherProps }: Props) => {
  return (
    <FormField
      element="textarea"
      elementProps={otherProps}
      fullWidth={fullWidth}
      error={error}
      label={label}
      variant={variant}
    />
  );
};

export default TextArea;
