import React from 'react';
import { css } from '@emotion/react';

import colors from '../styles/_colors.module.scss';
import Icon, { IconNames } from './icons/Icon';
import Box from './layout/Box';
import Flex from './layout/Flex';
import Text from './Text';

type AlertType = 'success' | 'error' | 'info';

export type Props = React.PropsWithChildren<{
  type: AlertType;
  title?: string;
  size?: 'sm' | 'md';
  className?: string;
}>;

const bgColorByType: { [P in AlertType]: string } = {
  success: colors.green100,
  error: '#fae0da',
  info: colors.interactiveHighlighterYellow,
};
const iconNamesByType: { [P in AlertType]: IconNames } = {
  success: 'checkCircle',
  error: 'exclamationCircle',
  info: 'questionCircle',
};

const defaultProps: Partial<Props> = {
  size: 'md',
};

const Alert = ({ type, title, size, children, className }: Props) => {
  const childrenWrapperProps = size === 'md' ? {} : { fontSize: '1.4rem', lineHeight: '1.29' };
  const textColor = type === 'error' ? colors.rust400 : undefined;
  const iconColor = type === 'error' ? colors.rust400 : colors.coreOrange;

  return (
    <div
      className={className}
      css={css`
        border-radius: 4px;
        padding: 16px;
        background-color: ${bgColorByType[type]};
      `}
    >
      <Flex alignItems="flex-start">
        <Box mr="1.2rem" mt={size === 'md' ? '0.3rem' : '0.2rem'} flexShrink={0}>
          <Icon name={iconNamesByType[type]} color={iconColor} height={size === 'md' ? '2rem' : '1.7rem'} />
        </Box>
        <Box flex={1} color={textColor}>
          {size === 'md' && (
            <Text el="div" variant="heading3xs" css={{ marginBottom: '0.4rem' }}>
              {title}
            </Text>
          )}
          {size === 'sm' && (
            <Box fontSize="1.4rem" fontWeight="bold" mb="1rem">
              {title}
            </Box>
          )}
          <Box {...childrenWrapperProps}>{children}</Box>
        </Box>
      </Flex>
    </div>
  );
};

Alert.defaultProps = defaultProps;

export default Alert;
