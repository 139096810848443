import React, { useContext } from 'react';

import { UserDataContext } from '../App';
import Link from './Link';
import { appConfig } from '../../commonConfig';

type Props = {
  className?: string;
  text?: string;
  variant?: 'btnSm' | 'btnSmOutline';
  edu?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  id?: string;
};

const defaultProps = {
  text: 'Get started',
  variant: 'btnSm',
};

const CallToAction = (props: Props) => {
  const href = props.edu ? `${appConfig.studioUrl}/sign-up?type=edu` : `${appConfig.studioUrl}/sign-up`;

  return (
    <Link className={props.className} variant={props.variant} href={href} onClick={props.onClick} id={props.id}>
      {props.text}
    </Link>
  );
};

CallToAction.defaultProps = defaultProps;

export default CallToAction;
